import * as React from 'react';
import Svg, { Defs, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" color="#000" width={24} height={24} {...props}>
    <Defs></Defs>
    <Path id="Path_7855" fill="none" d="M0 0h24v24H0z" />
    <Path id="Path_7856" fill="none" d="M12 13a3 3 0 0 1-3-3 3 3 0 0 1 3-3 3 3 0 0 1 3 3 3 3 0 0 1-3 3z" stroke="currentColor" />
    <Path id="Path_7857" fill="none" d="M12 21s-7-5.75-7-11a7 7 0 0 1 14 0c0 5.25-7 11-7 11z" stroke="currentColor" />
  </Svg>
);

export default SvgComponent;
