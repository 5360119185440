import * as Linking from 'expo-linking';
import i18n from 'i18n-js';
import { Input, useToast, FormControl, WarningOutlineIcon } from 'native-base';
import React, { useState, useEffect } from 'react';
import { BackHandler, Text, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';

import HeaderWithIcons from '../../components/common/HeaderWithIcons';
import IconEmail from '../../components/svg/IconEmail';
// import IconLock from '../../components/svg/IconLock';
import { useTheme } from '../../components/template/ThemeProvider';
import MYButton from '../../components/ui/MYButton';
import Myinput from '../../components/ui/MYInput';

export default function SingupScreen({ navigation }) {
  const { colors, isDark, setScheme } = useTheme();
  const toast = useToast();
  const [loadingBtn, setloadingBtn] = useState(false);
  const [emailValue, setemailValue] = useState(null);
  const userReduxState = useSelector((state) => state.user);

  const isRtl = userReduxState?.userSettings?.language?.toLowerCase() === 'ckb';

  useEffect(() => {
    const backAction = () => {
      navigation.goBack();
      return true;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  function handlePressNext() {
    if (!emailValue) {
      // toast
      toast.show({
        description: 'please enter your email',
      });
      return;
    }
    navigation.navigate('Auth_SetPassword', { email: emailValue });
  }

  return (
    <Container>
      <HeaderWithIcons title={i18n.t('auth.signup_header_title')} onPress={() => navigation.navigate('Welcome')} />
      <InnerContainer>
        <TopContainer>
          <FormControl isInvalid>
            <InputsBox>
              <Myinput
                type="text"
                variant="unstyled"
                InputLeftElement={<IconEmail color={isDark ? colors.bluey_grey : colors.dark} />}
                placeholder={i18n.t('auth.placeholder_email')} // mx={4}
                value={emailValue || ''}
                onChangeText={(txt) => setemailValue(txt)}
                // error="required"
              />
            </InputsBox>
            {/* <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>* Email is required</FormControl.ErrorMessage> */}
          </FormControl>
          <TermsText isRtl={isRtl}>
            {i18n.t('auth.by_tapping_register_you_accept_terms')}
            <TouchableWithoutFeedback onPress={() => Linking.openURL('https://www.jewankurd.com/terms')}>
              <TermsLink> {i18n.t('auth.terms_and_conditions')}</TermsLink>
            </TouchableWithoutFeedback>
            ,
            <TouchableWithoutFeedback onPress={() => Linking.openURL('https://www.jewankurd.com/privacy')}>
              <TermsLink> {i18n.t('auth.privacy_policy')}</TermsLink>
            </TouchableWithoutFeedback>
          </TermsText>
        </TopContainer>
      </InnerContainer>

      <ButtonsContainer isRtl={isRtl}>
        {isRtl ? (
          <SignUpBtn>
            <TouchableOpacity onPress={() => navigation.navigate('Auth_Login')}>
              <SignUpLink>{i18n.t('auth.login_button')}</SignUpLink>
            </TouchableOpacity>
            <DontHaveAccount>{i18n.t('auth.already_have_account')} </DontHaveAccount>
          </SignUpBtn>
        ) : (
          <SignUpBtn>
            <DontHaveAccount>{i18n.t('auth.already_have_account')} </DontHaveAccount>
            <TouchableOpacity onPress={() => navigation.navigate('Auth_Login')}>
              <SignUpLink>{i18n.t('auth.login_button')}</SignUpLink>
            </TouchableOpacity>
          </SignUpBtn>
        )}

        <MYButton color="red" style={{ width: '100%' }} onPress={handlePressNext} loading={loadingBtn}>
          {i18n.t('auth.continue')}
        </MYButton>
      </ButtonsContainer>
    </Container>
  );
}

const Container = styled.KeyboardAvoidingView`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
`;
const InnerContainer = styled.ScrollView`
  width: 100%;
`;
const ButtonsContainer = styled.View`
  padding: 0 34px;
  width: 100%;
  margin-bottom: 28px;
  flex-shrink: 0;
  text-align: ${(p) => (p.isRtl ? 'right' : 'left')};
`;
const TopContainer = styled.View`
  flex-shrink: 0;
  margin-bottom: auto;
  margin-top: 27px;
  width: 100%;
  padding: 0 35px;
`;
const InputsBox = styled.View`
  border-radius: 28px;
  border: solid 1px;
  border-color: ${(p) => (p.theme.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
  padding: 15px 20px;
`;
const InputsContainerSeperatorH = styled.View`
  height: 1px;
  background: ${(p) => (p.theme.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
`;
const SignUpBtn = styled.View`
  margin: 0 auto 29px;
  display: flex;
  flex-direction: row;
`;
const DontHaveAccount = styled.Text`
  text-align: center;
  color: ${(p) => (p.theme.isDark ? p.theme.myColors.bluey_grey : p.theme.myColors.dark2)};
  font-family: Gilroy-Medium;
  font-weight: 500;
`;
const SignUpLink = styled.Text`
  color: ${(p) => p.theme.myColors.coral};
  font-family: Gilroy-Medium;
`;
const TermsText = styled.Text`
  color: ${(p) => (p.theme.isDark ? p.theme.myColors.pale_grey2 : p.theme.myColors.bluey_grey)};
  font-family: Gilroy-Medium;
  font-size: 10px;
  margin-top: 34px;
  line-height: 18px;
  margin-left: 15px;
  text-align: ${(p) => (p.isRtl ? 'right' : 'left')};
`;
const TermsLink = styled.Text`
  color: ${(p) => p.theme.myColors.bright_sky_blue};
  font-family: Gilroy-Medium;
  font-size: 10px;
  /* line-height: 10px; */
  /* padding-top: 10px; */
`;
