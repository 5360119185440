import * as React from 'react';
import Svg, { Defs, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" color="#000" width={24} height={24} {...props}>
    <Defs></Defs>
    <Path id="Path_11380" fill="none" d="M0 0h24v24H0z" />
    <Path
      id="Path_11381"
      d="M7 9.815a1.472 1.472 0 0 1 1.135-.575 1.489 1.489 0 0 1 1.135.575"
      stroke="currentColor"
      transform="translate(1.215 2.64)"
      fill="none"
    />
    <Path
      id="Path_11382"
      d="M14 9.815a1.472 1.472 0 0 1 1.135-.575 1.489 1.489 0 0 1 1.135.575"
      stroke="currentColor"
      transform="translate(-.486 2.64)"
      fill="none"
    />
    <Path
      id="Path_11383"
      d="M14.63 5A6.812 6.812 0 1 1 5 5a6.812 6.812 0 0 1 9.63 0"
      stroke="currentColor"
      transform="translate(2.188 4.156)"
      fill="none"
    />
    <Path id="Path_11384" d="M13.8 14.688a4.03 4.03 0 0 1-5.3 0" stroke="currentColor" transform="translate(.851 1.316)" fill="none" />
    <Path
      id="Path_51368"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M13.976 5.078a2.43 2.43 0 0 0-.32-1.21 1.335 1.335 0 0 0-1.065-.623 1.923 1.923 0 0 0-1.292.241 1.415 1.415 0 0 0-.767.831 2.238 2.238 0 0 0-.178 1.822c.328.665.813.7 1.489.837s-.545 0-.545 0 .871.15.971.181"
    />
  </Svg>
);

export default SvgComponent;
