import * as React from 'react';
import Svg, { Defs, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" id="Plus_Add.1" color="#67679b" width={26.542} height={26.541} {...props}>
    <Defs></Defs>
    <Path id="Path_6076" fill="none" d="M0 0h26.542v26.541H0z" />
    <Path id="Path_6077" d="M12 8v8.847" stroke="currentColor" transform="translate(1.271 .847)" />
    <Path id="Path_6078" d="M16.847 12H8" stroke="currentColor" transform="translate(.847 1.271)" />
    <Path
      id="Path_6079"
      d="M12.953 22.906A9.952 9.952 0 0 1 3 12.953 9.952 9.952 0 0 1 12.953 3a9.952 9.952 0 0 1 9.953 9.953 9.952 9.952 0 0 1-9.953 9.953z"
      stroke="currentColor"
      transform="translate(.318 .318)"
      fill="none"
    />
  </Svg>
);

export default SvgComponent;
