import i18n from 'i18n-js';
import { useToast } from 'native-base';
import React, { useState, useEffect } from 'react';
import { Alert, BackHandler, TouchableOpacity, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';

import authApi from '../../apis/auth';
import HeaderWithIcons from '../../components/common/HeaderWithIcons';
import ForgetPassIcon from '../../components/svg/ForgetPassIcon';
import IconEmail from '../../components/svg/IconEmail';
import IconLock from '../../components/svg/IconLock';
import { useTheme } from '../../components/template/ThemeProvider';
import MYButton from '../../components/ui/MYButton';
import Myinput from '../../components/ui/MYInput';
import { doLogin } from '../../redux/slices/UserSlice';
import handleErrorResponse from '../../services/handleErrorResponse';

export default function LoginScreen({ navigation }) {
  const dispatch = useDispatch();
  const { colors, isDark, setScheme } = useTheme();
  const toast = useToast();
  const [loadingBtn, setloadingBtn] = useState(false);
  const [emailValue, setemailValue] = useState(null);
  const [passwordValue, setPasswordValue] = useState(null);
  const userReduxState = useSelector((state) => state.user);

  const isRtl = userReduxState?.userSettings?.language?.toLowerCase() === 'ckb';

  function alert(title = undefined, msg) {
    if (Platform.OS === 'web') {
      toast.show({ title: title, status: "info", description: msg })
    } else {
      Alert.alert(title, msg, [{ text: 'ok', onPress: () => null, style: 'cancel' }]
      );
    }
  }

  useEffect(() => {
    const backAction = () => {
      navigation.goBack();
      return true;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  function needToCompleteProfile(access_token) {
    // Do NOT store token. still not logged in!
    navigation.navigate('Auth_SelectGender', {
      email: emailValue,
      access_token: `${access_token}`,
    });
  }

  async function handleLogin(access_token, userInfo) {
    // dispatch login
    dispatch(doLogin({ access_token: `${access_token}`, userInfo }));
  }

  function handleSubmitLogin() {
    if (!emailValue || !passwordValue) {
      // toast
      toast.show({
        description: !emailValue ? 'Please enter email' : 'Please enter password',
      });
      return;
    }

    setloadingBtn(true);
    authApi
      .login({
        username: emailValue?.replace(' ', '').toLowerCase(),
        password: passwordValue,
      })
      .then(async (res) => {
        setloadingBtn(false);

        if (res?.data?.access_token) {
          if (res?.data?.isCompleteBasicProfile === false || !res?.data?.userInfo?.name) {
            return needToCompleteProfile(res?.data?.access_token);
          }

          return handleLogin(res?.data?.access_token, res?.data?.userInfo);
        } else {
          alert(null, 'Username or password is wrong');
        }

        setloadingBtn(false);
      })
      .catch((error) => {
        setloadingBtn(false);
        if (error.response?.data?.message === 'email_not_verified') {
          navigation.navigate('Auth_Verification', {
            email: emailValue,
            password: passwordValue,
          });

          return;
        }

        if (error.response) {
          // console.log('err', error.response);
          alert(null, error.response.data?.message === 'Unauthorized' ? 'Username or password is wrong' : handleErrorResponse(error.response.data?.message))
        }
      });
  }

  return (
    <Container>
      <HeaderWithIcons title={i18n.t('auth.login_header_title')} onPress={() => navigation.navigate('Welcome')} />
      <InnerContainer>
        <TopContainer>
          <InputsBox>
            <Myinput
              variant="unstyled"
              InputLeftElement={<IconEmail color={isDark ? colors.bluey_grey : colors.dark} />}
              placeholder={i18n.t('auth.placeholder_email')} // mx={4}
              value={emailValue || ''}
              onChangeText={(txt) => setemailValue(txt)}
            />
            <InputsContainerSeperatorH />
            <Myinput
              variant="unstyled"
              type="password"
              InputLeftElement={<IconLock color={isDark ? colors.bluey_grey : colors.dark} />}
              placeholder={i18n.t('auth.placeholder_password')} // mx={4}
              value={passwordValue || ''}
              onChangeText={(txt) => setPasswordValue(txt)}
            />
          </InputsBox>
          <TouchableOpacity onPress={() => navigation.navigate('Auth_ForgotPassword')}>
            <ForgetPassBox>
              <ForgetPassIcon />
              <ForgetPassTitle>{i18n.t('auth.forget_your_password')}</ForgetPassTitle>
            </ForgetPassBox>
          </TouchableOpacity>
        </TopContainer>
      </InnerContainer>

      <ButtonsContainer>
        {isRtl ? (
          <SignUpBtn>
            <TouchableOpacity onPress={() => navigation.navigate('Auth_Signup')}>
              <SignUpLink>{i18n.t('auth.register_button')}</SignUpLink>
            </TouchableOpacity>
            <DontHaveAccount>{i18n.t('auth.dont_have_account')} </DontHaveAccount>
          </SignUpBtn>
        ) : (
          <SignUpBtn>
            <DontHaveAccount>{i18n.t('auth.dont_have_account')} </DontHaveAccount>
            <TouchableOpacity onPress={() => navigation.navigate('Auth_Signup')}>
              <SignUpLink>{i18n.t('auth.register_button')}</SignUpLink>
            </TouchableOpacity>
          </SignUpBtn>
        )}

        <MYButton color="blue" style={{ width: '100%' }} onPress={handleSubmitLogin} loading={loadingBtn}>
          {i18n.t('auth.login_button')}
        </MYButton>
      </ButtonsContainer>
    </Container>
  );
}

const Container = styled.KeyboardAvoidingView`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;
const InnerContainer = styled.ScrollView`
  width: 100%;
`;

const ButtonsContainer = styled.View`
  padding: 0 34px;
  width: 100%;
  margin-bottom: 28px;
  flex-shrink: 0;
`;
const TopContainer = styled.View`
  flex-shrink: 0;
  margin-bottom: auto;
  margin-top: 27px;
  width: 100%;
  padding: 0 35px;
`;
const InputsBox = styled.View`
  border-radius: 28px;
  border: solid 1px;
  border-color: ${(p) => (p.theme.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
  padding: 15px 20px;
`;
const InputsContainerSeperatorH = styled.View`
  height: 1px;
  margin: 10px 0;
  background: ${(p) => (p.theme.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
`;
const ForgetPassBox = styled.View`
  margin: 21px auto;
  display: flex;
  flex-direction: row;
`;
const SignUpBtn = styled.View`
  margin: 0 auto 29px;
  display: flex;
  flex-direction: row;
`;
const ForgetPassTitle = styled.Text`
  text-align: center;
  color: ${(p) => p.theme.myColors.coral};
  margin-left: 6px;
  font-family: Gilroy-Medium;
`;
const DontHaveAccount = styled.Text`
  text-align: center;
  color: ${(p) => (p.theme.isDark ? p.theme.myColors.bluey_grey : p.theme.myColors.dark2)};
  font-family: Gilroy-Medium;
`;
const SignUpLink = styled.Text`
  color: ${(p) => p.theme.myColors.coral};
  font-family: Gilroy-Medium;
`;
