import i18n from 'i18n-js';
import { Actionsheet, useDisclose } from 'native-base';
import React from 'react';
import { FlatList } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';

import HeaderWithIcons from '../../components/common/HeaderWithIcons';
import ActionSheetDeleteAccount from '../../components/pages/myProfile/ActionSheetDeleteAccount';
import ActionSheetLogOut from '../../components/pages/myProfile/ActionSheetLogOut';
import IconArrowRight from '../../components/svg/IconArrowRight';
import IconBlock from '../../components/svg/IconBlock';
import IconDelete2 from '../../components/svg/IconDelete2';
import IconEmail2 from '../../components/svg/IconEmail2';
import IconLanguage from '../../components/svg/IconLanguage';
import IconLogout from '../../components/svg/IconLogout';
import IconNotification from '../../components/svg/IconNotification';
import IconPassword from '../../components/svg/IconPassword';
import IconSubscribeStar2 from '../../components/svg/IconSubscribeStar2';
import { useTheme } from '../../components/template/ThemeProvider';
import { doLogOut, setUserSettings } from '../../redux/slices/UserSlice';
import { isDateBeforeToday } from '../../utils/dateHelper';

export default function SettingsScreen({ navigation }) {
  const dispatch = useDispatch();
  const userReduxState = useSelector((state) => state.user);
  const { isOpen, onOpen, onClose } = useDisclose();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclose();
  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclose();
  const { colors, isDark } = useTheme();

  function changeAppLanguage(lang) {
    dispatch(setUserSettings({ language: `${lang}` }));
    setTimeout(() => {
      onClose();
    }, 400);
  }

  const DATA = [
    {
      title: i18n.t('settings.menu_subscribe'),
      icon: <IconSubscribeStar2 />,
      type: 'subscription',
    },
    {
      title: i18n.t('settings.menu_your_email'),
      icon: <IconEmail2 />,
      type: 'email',
    },
    {
      title: i18n.t('settings.menu_password'),
      icon: <IconPassword />,
      onClick: () => navigation.navigate('ChangePassword'),
    },
    // {
    //   title: i18n.t('settings.menu_notifications'),
    //   icon: <IconNotification />,
    // },
    {
      title: i18n.t('settings.menu_block_user'),
      icon: <IconBlock />,
      onClick: () => navigation.navigate('BlockedUsersScreen'),
    },
    {
      title: i18n.t('settings.menu_language'),
      icon: <IconLanguage />,
      onClick: onOpen,
    },
    {
      title: i18n.t('settings.menu_delete_account'),
      icon: <IconDelete2 />,
      type: 'delete_account',
      onClick: onOpen2,
    },
    {
      title: i18n.t('settings.menu_log_out'),
      icon: <IconLogout />,
      type: 'logout',
      onClick: onOpen3,
    },
  ];

  const subscriptionExp = userReduxState?.userInfo?.subscriptionExp;
  let daysLeft = null;

  if (subscriptionExp && !isDateBeforeToday(new Date(subscriptionExp))) {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(subscriptionExp);
    const secondDate = new Date();

    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    daysLeft = diffDays;
  }

  const Item = ({ title, icon, type, onClick }) =>
    type === 'delete_account' || type === 'logout' ? (
      <TouchableOpacity onPress={onClick || null}>
        <MenuItem2>
          {icon}
          <MenuTxt>{title}</MenuTxt>
        </MenuItem2>
      </TouchableOpacity>
    ) : type === 'subscription' ? (
      <MenuItem>
        {icon}
        <MenuTxt>{title}</MenuTxt>
        <MenuDesc>{!subscriptionExp || !daysLeft ? '-' : daysLeft + ' ' + i18n.t('settings.subscription_days_left_prefix')}</MenuDesc>
      </MenuItem>
    ) : type === 'email' ? (
      <MenuItem>
        {icon}
        <MenuTxt>{title}</MenuTxt>
        <MenuDesc>{userReduxState?.userInfo?.email}</MenuDesc>
      </MenuItem>
    ) : (
      <TouchableOpacity onPress={onClick || null}>
        <MenuItem>
          {icon}
          <MenuTxt>{title}</MenuTxt>
          <IconArrowRight color={isDark ? colors.white : colors.dark2} />
        </MenuItem>
      </TouchableOpacity>
    );

  return (
    <Container>
      <HeaderWithIcons title={i18n.t('settings.header_title')} onPress={() => navigation.goBack()} />
      <FlatList
        data={DATA}
        renderItem={({ item }) => <Item title={item.title} icon={item?.icon} type={item?.type} onClick={item?.onClick} />}
        keyExtractor={(item, index) => index}
        ItemSeparatorComponent={({ highlighted }) => <MenuItemSeperator />}
        style={{ paddingLeft: 35, marginTop: 38 }}
      />
      <Actionsheet isOpen={isOpen} onClose={onClose}>
        <Actionsheet.Content>
          <Actionsheet.Item onPress={() => changeAppLanguage('en')}>{i18n.t('auth.language_EN')}</Actionsheet.Item>
          <Actionsheet.Item onPress={() => changeAppLanguage('ckb')}>{i18n.t('auth.language_CKB')}</Actionsheet.Item>
          <Actionsheet.Item onPress={() => changeAppLanguage('kur')}>{i18n.t('auth.language_KUR')}</Actionsheet.Item>
        </Actionsheet.Content>
      </Actionsheet>

      <Actionsheet isOpen={isOpen2} onClose={onClose2}>
        <ActionSheetDeleteAccount onClose={onClose2} />
      </Actionsheet>

      <Actionsheet isOpen={isOpen3} onClose={onClose3}>
        <ActionSheetLogOut onClose={onClose3} />
      </Actionsheet>
    </Container>
  );
}

const Container = styled.View`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
  justify-content: space-between;
  /* padding: 42px 39px 0; */
`;

const MenuItem = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
  padding-right: 35px;
`;
const MenuItem2 = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
  padding-right: 35px;
  opacity: 0.5;
`;
const MenuTxt = styled.Text`
  flex: 1;
  font-family: Gilroy-Medium;
  font-size: 14px;
  margin-left: 11px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.dusk)};
  text-align: left;
`;
const MenuDesc = styled.Text`
  font-family: Gilroy-Medium;
  font-size: 14px;
  margin-left: 11px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.dusk)};
`;
const MenuItemSeperator = styled.View`
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey2)};
  height: 1px;
  margin-left: 20px;
`;
