import i18n from 'i18n-js';
import { useToast } from 'native-base';
import React, { useState, useEffect } from 'react';
import { Alert, TouchableOpacity, BackHandler, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';

import LogoDark__ from '../../components/svg/LogoDark';
import LogoLight_ from '../../components/svg/LogoLight';
import Tick1_ from '../../components/svg/Tick1';
import WelcomeDark_ from '../../components/svg/WelcomeDark';
import WelcomeLight_ from '../../components/svg/WelcomeLight';
import { useTheme } from '../../components/template/ThemeProvider';
import MYButton from '../../components/ui/MYButton';
import { setUserSettings } from '../../redux/slices/UserSlice';

export default function WelcomeScreen({ navigation }) {
  const dispatch = useDispatch();
  const { colors, isDark, setScheme } = useTheme();
  const userReduxState = useSelector((state) => state.user);
  const [selectedLang, setSelectedLang] = useState('ckb');

  const toast = useToast();

  function alert(title = undefined, msg, footer = [{ text: 'ok', onPress: () => null, style: 'cancel' }]) {
    if (Platform.OS === 'web') {
      toast.show({ title: title, status: 'info', description: msg });
    } else {
      Alert.alert(title, msg, footer);
    }
  }

  useEffect(() => {
    const backAction = () => {
      alert('Hold on!', 'Are you sure you want to exit the app?', [
        {
          text: 'Cancel',
          onPress: () => null,
          style: 'cancel',
        },
        { text: 'YES', onPress: () => BackHandler.exitApp() },
      ]);
      return true;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  useEffect(() => {
    setSelectedLang(userReduxState?.userSettings?.language || 'ckb');
  }, [userReduxState?.userSettings?.language]);

  function changeAppLanguage(lang) {
    dispatch(setUserSettings({ language: `${lang}` }));
    setTimeout(() => {
      setSelectedLang(lang);
    }, 400);
  }

  return (
    <ScrollViewContainer contentContainerStyle={{ flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }}>
      {isDark ? <LogoDark /> : <LogoLight />}

      <ImageContainer>{isDark ? <WelcomeDark /> : <WelcomeLight />}</ImageContainer>

      <ButtonsContainer>
        <Title>{i18n.t('auth.please_select_language')}</Title>
        <LanguageSelectContainer>
          <TouchableOpacity onPress={() => changeAppLanguage('en')}>
            <LanguageBox selected={selectedLang === 'en'}>
              <LanguageBoxText selected={selectedLang === 'en'}>{i18n.t('auth.language_EN')}</LanguageBoxText>
              {selectedLang === 'en' && <CheckedIcon />}
            </LanguageBox>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => changeAppLanguage('ckb')}>
            <LanguageBox selected={selectedLang === 'ckb'}>
              <LanguageBoxText selected={selectedLang === 'ckb'}>{i18n.t('auth.language_CKB')}</LanguageBoxText>
              {selectedLang === 'ckb' && <CheckedIcon />}
            </LanguageBox>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => changeAppLanguage('kur')}>
            <LanguageBox selected={selectedLang === 'kur'}>
              <LanguageBoxText selected={selectedLang === 'kur'}>{i18n.t('auth.language_KUR')}</LanguageBoxText>
              {selectedLang === 'kur' && <CheckedIcon />}
            </LanguageBox>
          </TouchableOpacity>
        </LanguageSelectContainer>

        <MYButton color="red" style={{ width: '100%' }} onPress={() => navigation.navigate('Auth_Signup')}>
          {i18n.t('auth.register_button')}
        </MYButton>
        <MYButton style={{ width: '100%', marginTop: 12 }} onPress={() => navigation.navigate('Auth_Login')}>
          {i18n.t('auth.login_button')}
        </MYButton>
      </ButtonsContainer>
    </ScrollViewContainer>
  );
}

const ScrollViewContainer = styled.ScrollView`
  width: 100%;
  height: 100%;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  display: flex;
`;
const LogoLight = styled(LogoLight_)`
  flex-shrink: 0;
  margin: 64px auto 55px;
`;
const LogoDark = styled(LogoDark__)`
  margin: 64px auto 55px;
  flex-shrink: 0;
`;
const ImageContainer = styled.View`
  position: relative;
  width: 100%;
  /* height: ${Platform.OS === 'web' ? '155px' : 'auto'}; */
  height: auto;
  flex-shrink: 1;
  min-height: 40px;
  padding: 0 40px;
  /* padding: 25px 0; */
  /* height: 40%; */
`;
const WelcomeDark = styled(WelcomeDark_)`
  margin: 0 auto;
  /* display: block; */
  /* z-index: 2; */
  width: 100%;
  height: 100%;
`;
const WelcomeLight = styled(WelcomeLight_)`
  margin: 0 auto;
  /* display: block; */
  /* z-index: 2; */
  width: 100%;
  height: 100%;
`;
const ButtonsContainer = styled.View`
  padding: 0 34px;
  width: 100%;
  margin-bottom: 28px;
  flex-shrink: 0;
  margin-top: auto;
`;
const Title = styled.Text`
  font-size: 14.4px;
  font-weight: 500;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dusk)};
  text-align: center;
  padding: 0 40px;
  margin: 10% auto 28px;
  font-family: Gilroy-Medium;
  height: 40px;
`;
const LanguageSelectContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
`;
const LanguageBox = styled.View`
  border: 1px solid;
  border-radius: 8px;
  padding: 7px 20px;
  border-color: ${(p) => (p.selected ? p.theme.myColors.bright_sky_blue : p.theme?.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
  position: relative;
`;
const LanguageBoxText = styled.Text`
  font-family: Gilroy-Medium;
  color: ${(p) =>
    p.selected
      ? p.theme?.isDark
        ? p.theme.myColors.white
        : p.theme.myColors.dark
      : p.theme?.isDark
      ? p.theme.myColors.light_blue_grey
      : p.theme.myColors.slate_blue};
`;
const CheckedIcon = styled(Tick1_)`
  position: absolute;
  top: -7px;
  right: -7px;
`;
