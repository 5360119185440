import { manipulateAsync } from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import i18n from 'i18n-js';
import { Spinner, useToast } from 'native-base';
// import DatePicker from 'react-native-date-picker';
import React, { useState, useEffect } from 'react';
import { BackHandler, StyleSheet, Image, TouchableOpacity, View, Alert, Platform } from 'react-native';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/native';

import authApi from '../../apis/auth';
import usersApi from '../../apis/users';
import HeaderWithIcons from '../../components/common/HeaderWithIcons';
import IconCameraTopImage from '../../components/svg/IconCameraTopImage';
import { useTheme } from '../../components/template/ThemeProvider';
import MYButton from '../../components/ui/MYButton';
import { close, open } from '../../redux/slices/ModalSlice';
import { doLogin } from '../../redux/slices/UserSlice';
import handleErrorResponse from '../../services/handleErrorResponse';
import { DataURIToBlob } from '../../utils/utils';

export default function SetAvatar({ route, navigation }) {
  const dispatch = useDispatch();
  const { colors, isDark, setScheme } = useTheme();
  const toast = useToast();
  const [loadingBtn, setloadingBtn] = useState(false);
  const { email, gender, name, birthday, access_token, countryCode, state, city } = route.params || {};

  const [image, setImage] = useState(null);

  function alert(title = undefined, msg) {
    if (Platform.OS === 'web') {
      toast.show({ title: title, status: 'info', description: msg });
    } else {
      Alert.alert(title, msg, [{ text: 'ok', onPress: () => null, style: 'cancel' }]);
    }
  }

  useEffect(() => {
    const backAction = () => {
      navigation.goBack();
      return true;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          alert(null, 'Sorry, we need camera roll permissions to make this work!');
        }
      }
    })();
  }, []);

  async function handleLogin(userInfo) {
    // dispatch login
    dispatch(doLogin({ access_token: `${access_token}`, userInfo }));
  }

  function handleSubmitLogin() {
    setloadingBtn(true);
    // console.log('access_token in save segment', access_token);

    authApi
      .updateProfile(
        {
          name,
          genderEnumId: gender === 'man' ? '1' : '2',
          birthdate: birthday,
          countryCode,
          state,
          city,
        },
        access_token
      )
      .then(async (res) => {
        setloadingBtn(false);
        // console.log(res);
        handleLogin(res?.data?.userInfo);
      })
      .catch((error) => {
        setloadingBtn(false);
        // console.log(error.response.data);
        alert('Error', error.response.data?.message ? handleErrorResponse(error.response.data?.message) : 'Error in saving data');
      });
  }

  function handlePressNext() {
    handleSubmitLogin();
  }

  function handlePressSkip() {
    handleSubmitLogin();
  }

  function handleOpenModal() {
    dispatch(
      open({
        content: () => (
          <View style={{ paddingTop: 50, paddingBottom: 50 }}>
            <Spinner accessibilityLabel="Loading" />
          </View>
        ),
      })
    );
  }

  async function uploadAvatarImageToServer(imageUri) {
    if (!imageUri) return;

    const file = DataURIToBlob(imageUri);

    handleOpenModal();
    const formData = new FormData();

    if (Platform.OS === 'web') {
      formData.append('file', file);
    } else {
      formData.append('file', {
        uri: imageUri,
        name: `profile_photo.jpeg`,
        type: `image/jpeg`,
      });
    }
    await usersApi
      .updateProfileAvatar(formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then(() => {
        dispatch(close());
        setImage(imageUri);
      })
      .catch((err) => {
        console.log(err);
        dispatch(close());
        alert(null, 'Server Error 500');
      });
  }

  const takeImage = async () => {
    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    if (!result.cancelled) {
      setImage(result.uri);
    }
  };

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      base64: true,
      exif: false,
    });

    const manipResult = await manipulateAsync(result.uri, [{ resize: { height: 500, width: 500 } }], { format: 'jpeg' });

    if (!result.cancelled && manipResult.uri) {
      uploadAvatarImageToServer(manipResult.uri);
    }
  };

  function handleRemoveImage() {
    setImage(null);
  }

  return (
    <Container>
      <HeaderWithIcons
        onPress={() => navigation.goBack()}
        // rightNode={
        //   !image && (
        //     <TouchableOpacity onPress={handlePressSkip}>
        //       <SkipTxt>Skip</SkipTxt>
        //     </TouchableOpacity>
        //   )
        // }
      />

      <SelectContainer>
        <TopImage>
          <IconCameraTopImage />
        </TopImage>

        <Title>{i18n.t('auth.add_a_photo_of_yourself')}</Title>

        <ImageBox>
          <ImageBoxImage
            source={
              image
                ? { uri: image }
                : gender === 'woman'
                ? require('../../assets/images/defaultavatar2.png')
                : require('../../assets/images/defaultavatar1.png')
            }
          />
        </ImageBox>

        <Description>{i18n.t('auth.you_can_change_the_profile_picture_later')}</Description>
      </SelectContainer>

      <ButtonsContainer>
        {!image ? (
          <>
            {/* <MYButton color="blue" style={{ width: '100%' }} onPress={takeImage} loading={loadingBtn}>
              Take a picture
            </MYButton> */}
            <MYButton color="slateblue" style={{ width: '100%', marginTop: 13 }} onPress={pickImage} loading={loadingBtn}>
              Upload Form Gallrey
            </MYButton>
          </>
        ) : (
          <>
            <TouchableOpacity onPress={handleRemoveImage}>
              <RemoveImage>{i18n.t('auth.remove_selected_image')}</RemoveImage>
            </TouchableOpacity>
            <MYButton color="blue" style={{ width: '100%' }} onPress={handlePressNext} loading={loadingBtn}>
              {i18n.t('auth.continue')}
            </MYButton>
          </>
        )}
      </ButtonsContainer>
    </Container>
  );
}

const Container = styled.View`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
`;

const ButtonsContainer = styled.View`
  padding: 0 34px;
  width: 100%;
  margin-bottom: 28px;
  flex-shrink: 0;
`;

const SelectContainer = styled.View`
  margin-top: 7%;
  flex: 1;
  width: 100%;
  padding: 0 35px;
`;

const TopImage = styled.View`
  margin: 0 auto 26px;
`;

const Title = styled.Text`
  font-family: Gilroy-Bold;
  font-size: 22px;
  text-align: center;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dusk)};
`;

const Description = styled.Text`
  font-family: Gilroy-Medium;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.bluey_grey)};
`;

const RemoveImage = styled.Text`
  font-family: Gilroy-Medium;
  margin-bottom: 18px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dusk)};
`;

const ImageBox = styled.View`
  padding: 5px;
`;

const ImageBoxImage = styled.Image`
  width: 150px;
  height: 150px;
  border-radius: 23px;
  padding: 5px;
  /* border: 1px solid #ddd; */
  margin: 30px auto 28px;
`;

const SkipTxt = styled.Text`
  font-family: Gilroy-Medium;
  font-size: 16px;
  margin-top: 10px;
  font-weight: 500;
  text-align: center;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.slate_blue : p.theme.myColors.bluey_grey)};
`;
