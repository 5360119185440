import * as React from 'react';
import Svg, { G, Path, Circle } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width={54.021} height={83.311} viewBox="0 0 54.021 83.311" {...props}>
      <G data-name="Group 13721">
        <G data-name="character design">
          <Path data-name="Path 20234" d="M33.179 36.746l.816 16.93s-15.856 1.612-15.856.807V37.551l1.612-3.762z" fill="#d6d6d6" />
          <Path
            data-name="Path 20235"
            d="M21.158 32.878l10.571 7.347c2.419 4.209-5.152.985-8.376 9.048l-2.644 1.97-.8-10.033-.449-6.72z"
            fill="#c5c5c5"
            opacity={0.62}
          />
          <Path
            data-name="Path 20236"
            d="M47.952 48.564l-17.795.11-.636 1.572-1.248 1.443-1.256 1.441-1.256-1.441-1.256-1.438-.977-1.695H6.07c-3.339 0-6.071 1.807-6.071 4.025v26.648c0 2.207 2.733 4.014 6.071 4.014h41.881c3.339 0 6.069-1.807 6.069-4.014V52.581c.002-2.21-2.729-4.017-6.068-4.017z"
            fill="#7c7c7c"
          />
          <Path data-name="Path 20237" d="M36.447 48.677v34.634h11.408v-34.74z" fill="#9d9d9d" />
          <Path data-name="Path 20238" d="M19.187 48.558l-11.408.011v34.669h11.408z" fill="#9d9d9d" />
          <Path
            data-name="Path 20239"
            d="M34.685 15.765s4.569 2.061 4.025 6.093-.718 11.016.8 12.632 2.061-7.616 2.061-7.974-.777-8.87-.777-8.87l-3.343-3.313z"
            fill="#dbaf9e"
            opacity={0.39}
          />
          <Path
            data-name="Path 20240"
            d="M40.863 17.646A11.1 11.1 0 0144.805 27.8c-.129.816-1.674 4.951-1.907 5.637-1.18 3.6-.269 3.508 2.076 7.234a7.135 7.135 0 011.313 5.173c-.28 1.483-1.758 2.777-2.256 3.614a3.633 3.633 0 00-.4 3.178c.182.506 1.794 2.741 1.96 3.4.822 3.273-1.33 7.978-4.66 9.228a5.8 5.8 0 01-4.709-.025c-3.695-1.807-6.518-6.3-7.164-7.8-1.394-3.237-.847-5.932.6-8.685.39-.741 2.176-3.745 2.351-4.394a4.1 4.1 0 00-.828-3.921 8.554 8.554 0 01-2.065-2.979 8.448 8.448 0 01.1-4.536c.182-.6 1.553-3.284 1.841-4a21.224 21.224 0 001.466-7.059s6.462-4.219 8.34-4.219z"
            fill="#23282d"
          />
          <Path
            data-name="Path 20241"
            d="M40.747 17.707c-.4 2.966-1.936 16.159-6.031 21.114-.919 1.112-2.6 2.966-6.453 2.157-9.649-2-17.727-17.229-18.364-20.972 0 0 23.336-9.662 30.789-2.568"
            fill="#d6d6d6"
          />
          <Path
            data-name="Path 20242"
            d="M36.388 16.575s-7.711 0-10.728 2.519c-.733.612-1.159 3.347-1.112 3.851.375 4.2 1.82 7.963-4.112 12.435l-3.218-17.651 13.45-5.654z"
            fill="#cbcbcb"
            opacity={0.39}
          />
          <Path
            data-name="Path 20243"
            d="M40.807 17.647a15.463 15.463 0 00-4.12-12.482A15.257 15.257 0 0024.673.028S17.983-.688 12.31 6.36s-2.15 15.111-2.15 15.111l.6 1.493a36.464 36.464 0 0130.047-5.317z"
            fill="#222223"
          />
          <Path
            data-name="Path 20244"
            d="M37.536 6.16a27.612 27.612 0 00-3.993.6 34.166 34.166 0 00-3.94 1.078 38.649 38.649 0 00-3.8 1.483 36.535 36.535 0 00-10.084 6.84c-.481.483-.981.949-1.432 1.46l-.693.754-.663.78-.335.392c-.112.129-.212.267-.318.4l-.636.809c-.212.263-.4.553-.606.826l-.536.737-.04-.106c.371-.576.636-1.013 1.023-1.574l.6-.837.3-.424.318-.407.635-.809.68-.777c.443-.527.934-1.01 1.407-1.513a32.746 32.746 0 013.1-2.743l.4-.322.424-.305c.282-.212.555-.424.847-.606l.862-.574c.286-.193.587-.362.879-.544a33.4 33.4 0 017.518-3.368 28 28 0 017.993-1.347z"
            fill="#fff"
          />
          <Path
            data-name="Path 20245"
            d="M27.756.214a22.989 22.989 0 00-3.008 1.451 33.727 33.727 0 00-10.1 8.717l-.263.33-.248.339-.5.678-.475.695-.237.347-.225.364a45.3 45.3 0 00-3.209 6.1l-.025-.136a36.311 36.311 0 012.966-6.131 32.547 32.547 0 014.074-5.438 30.332 30.332 0 015.152-4.449 25.485 25.485 0 012.945-1.712A19.445 19.445 0 0126.16.683a11.149 11.149 0 011.466-.5z"
            fill="#fff"
          />
          <Circle data-name="Ellipse 324" cx={0.402} cy={0.402} r={0.402} transform="translate(27.175 7.951)" fill="#f4f4f4" />
          <Circle data-name="Ellipse 325" cx={0.436} cy={0.436} r={0.436} transform="translate(35.748 5.641)" fill="#f4f4f4" />
          <Circle data-name="Ellipse 326" cx={0.383} cy={0.383} r={0.383} transform="translate(22.301 2.053)" fill="#b6b6b6" />
          <Circle data-name="Ellipse 327" cx={0.538} cy={0.538} r={0.538} transform="translate(20.337 11.226)" fill="#f4f4f4" />
          <Circle data-name="Ellipse 328" cx={0.46} cy={0.46} r={0.46} transform="translate(14.401 16.46)" fill="#f4f4f4" />
          <Circle data-name="Ellipse 329" cx={0.538} cy={0.538} r={0.538} transform="translate(9.749 21.977)" fill="#f4f4f4" />
          <Circle
            data-name="Ellipse 330"
            cx={0.538}
            cy={0.538}
            r={0.538}
            transform="translate(12.721 25.91)"
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
          />
          <Path
            data-name="Path 20246"
            d="M19.352 18.61a8.005 8.005 0 014.025 8.751c-.131.816-1.239 3.976-1.455 4.546-2.381 6.3-1.621 5.313 1.171 9.745a7.135 7.135 0 011.313 5.173c-.28 1.483-1.758 2.777-2.256 3.614a3.634 3.634 0 00-.4 3.178c.182.506 1.794 2.741 1.96 3.4.822 3.273-1.33 7.978-4.661 9.228a5.8 5.8 0 01-4.709-.025c-3.695-1.807-6.518-6.3-7.164-7.8-1.394-3.237-.847-5.932.6-8.685.39-.741 2.176-3.745 2.351-4.394a4.1 4.1 0 00-.828-3.921 8.553 8.553 0 01-2.065-2.979 8.448 8.448 0 01.1-4.536c.182-.593 1.553-3.284 1.841-4a23.053 23.053 0 001.576-6.978s5.912-4.092 8.601-4.317z"
            fill="#23282d"
          />
          <Path
            data-name="Path 20247"
            d="M3.459 40.987a10.225 10.225 0 01.049-1.957 17.379 17.379 0 011.441-4.6 29.025 29.025 0 015.055-6.9c3-2.837 4.487-7.171 3.6-9.149"
            fill="none"
            stroke="#fff"
            strokeMiterlimit={10}
          />
          <Path
            data-name="Path 20248"
            d="M13.204 26.389a18.253 18.253 0 01-.788-3.6 9.533 9.533 0 01-.036-1.856 4.08 4.08 0 01.53-1.773l.047.028a4.448 4.448 0 00-.455 1.756 11.036 11.036 0 00.034 1.832 17.084 17.084 0 00.28 1.82c.127.6.267 1.2.441 1.79z"
            fill="#fff"
          />
          <Path
            data-name="Path 20249"
            d="M7.465 30.664a20.5 20.5 0 001.483-2.288 22.9 22.9 0 001.191-2.449c.182-.424.322-.847.479-1.271a11.992 11.992 0 00.394-1.3 25.556 25.556 0 00.578-2.663h.053a13.643 13.643 0 01-.386 2.724c-.051.225-.1.447-.176.665l-.212.659c-.163.424-.311.866-.5 1.284s-.379.837-.58 1.25l-.328.606-.35.593a15.529 15.529 0 01-1.612 2.224z"
            fill="#fff"
          />
          <Path
            data-name="Path 20250"
            d="M37.229 9.605l-.983-2.716a.612.612 0 01.612-.818l1.6.1 1.364.081a.612.612 0 01.447.987l-1.981 2.542a.612.612 0 01-1.057-.172z"
            fill="#f4f4f4"
          />
          <Path
            data-name="Path 20251"
            d="M20.65 12.499a.53.53 0 01.9-.129l.862 1.1.864 1.1a.527.527 0 01-.341.847l-1.381.2-1.383.2a.527.527 0 01-.564-.718l.519-1.3z"
            fill="#f4f4f4"
          />
          <Path
            data-name="Path 20252"
            d="M27.298 9.01a.551.551 0 01.911-.286l1.076.991 1.076.991a.551.551 0 01-.212.932l-1.392.436-1.4.436a.551.551 0 01-.7-.648l.32-1.426z"
            fill="#f4f4f4"
          />
          <Path
            data-name="Path 20253"
            d="M23.823 2.754a.587.587 0 00-.958.35l-.269 1.536-.269 1.536a.589.589 0 00.782.655l1.464-.536 1.466-.534a.589.589 0 00.176-1l-1.195-1z"
            fill="#8a8a8a"
          />
          <Path
            data-name="Path 20254"
            d="M14.975 17.669a.589.589 0 01.833-.589l1.415.655 1.417.652a.589.589 0 01.093 1.015l-1.271.9-1.271.9a.589.589 0 01-.926-.424l-.142-1.553z"
            fill="#f4f4f4"
          />
          <Path
            data-name="Path 20255"
            d="M14.872 21.551c-.038-.722.489-.928.949-.371l1.587 1.921 1.587 1.921c.46.559.508 1.462.087 1.627l-1.453.566-1.453.566c-.424.165-1-.532-1.036-1.254l-.133-2.489z"
            fill="#8a8a8a"
          />
          <Path
            data-name="Path 20256"
            d="M6.518 27.046a.46.46 0 01-.195-.771l1.752-1.695 1.75-1.695a.46.46 0 01.765.212l.6 2.366.6 2.366a.46.46 0 01-.57.555l-2.347-.667z"
            fill="#f4f4f4"
          />
          <Path
            data-name="Path 20257"
            d="M10.479 32.682a.212.212 0 01-.191-.292l1.385-2.729 1.39-2.726a.212.212 0 01.35-.019l1.667 2.567 1.665 2.568a.212.212 0 01-.159.311l-3.055.161z"
            fill="#8a8a8a"
          />
          <Path
            data-name="Path 20258"
            d="M1.305 46.404a.182.182 0 01-.195-.248l1.019-2.561 1.019-2.563a.182.182 0 01.311-.044l1.71 2.163 1.71 2.163a.184.184 0 01-.117.294l-2.729.4z"
            fill="#f4f4f4"
          />
          <Path
            data-name="Path 20259"
            d="M17.895 10.893a.746.746 0 00-.636 1.15c-.744-.064-1.79-.458-1.271-2.262a8.51 8.51 0 012.277-3.682l-1.127.972a6.352 6.352 0 00-1.695 3.4 2.308 2.308 0 00.555 1.733c.881.833 2.436 1.03 2.627-.568a.756.756 0 00-.73-.743z"
            fill="#fff"
          />
          <Path data-name="Path 20260" d="M18.284 6.103l.6-.521a6.121 6.121 0 00-.6.521z" fill="#fff" />
          <Path
            data-name="Path 20261"
            d="M33.146 12.058a.748.748 0 01.748.748.731.731 0 01-.121.4c.746-.064 1.792-.458 1.271-2.263a8.529 8.529 0 00-2.277-3.682l1.127.972a6.352 6.352 0 011.695 3.4 2.321 2.321 0 01-.555 1.731c-.883.835-2.438 1.032-2.629-.566a.8.8 0 01.741-.74z"
            fill="#fff"
          />
          <Path data-name="Path 20262" d="M32.772 7.268l-.606-.521a6.261 6.261 0 01.606.521z" fill="#fff" />
          <Path
            data-name="Path 20263"
            d="M34.6 13.172a3.29 3.29 0 00-.051.354 2.31 2.31 0 00.555 1.733c.881.833 2.436 1.03 2.627-.568a.8.8 0 00-.746-.748.746.746 0 00-.636 1.15c-.71-.061-1.695-.424-1.333-2.015"
            fill="#fff"
          />
          <Path data-name="Path 20264" d="M37.367 9.149l.6-.521a6.114 6.114 0 00-.6.521z" fill="#fff" />
          <Path
            data-name="Path 20265"
            d="M40.807 17.646a30.008 30.008 0 00-3.254-.636s3.911 1.943 1.165 12.141c0 .009 1.985-9.662 2.089-11.505z"
            fill="#bababa"
          />
          <Circle data-name="Ellipse 331" cx={0.46} cy={0.46} r={0.46} transform="translate(14.558 20.178)" fill="#b6b6b6" />
          <Circle data-name="Ellipse 332" cx={0.46} cy={0.46} r={0.46} transform="translate(12.901 26.203)" fill="#b6b6b6" />
          <Path
            data-name="Path 20266"
            d="M4.368 47.525a8.114 8.114 0 011.027-4.184c.455-.824 2.873-4.5 3.726-6.419 2.118-4.737 4.345-12.1 3.019-16.947"
            fill="none"
            stroke="#fff"
            strokeMiterlimit={10}
          />
          <Path
            data-name="Path 20267"
            d="M2.788 53.117a.341.341 0 01-.424-.445l.864-2.345.864-2.343a.341.341 0 01.606-.07l1.1 1.669 1.214 1.845a.341.341 0 01-.191.517l-2.118.614z"
            fill="#8a8a8a"
          />
          <Circle data-name="Ellipse 333" cx={0.538} cy={0.538} r={0.538} transform="translate(6.853 30.077)" fill="#f9f8d3" />
          <Path
            data-name="Path 20268"
            d="M5.645 36.225a.307.307 0 01-.33-.424l.958-2.409.958-2.406a.309.309 0 01.527-.078l1.606 2.034 1.608 2.034a.307.307 0 01-.2.494l-2.565.375z"
            fill="#f4f4f4"
          />
        </G>
      </G>
    </Svg>
  );
}

export default SvgComponent;
