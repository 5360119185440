import * as React from 'react';
import Svg, { Defs, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" color="#000" width={24} height={24} {...props}>
    <Defs></Defs>
    <Path id="Path_7744" fill="none" d="M0 0h24v24H0z" />
    <Path id="Path_7745" d="M3 12h4.172A2.828 2.828 0 0 0 10 9.172a2.829 2.829 0 0 0-.828-2L6.717 4.717" stroke="currentColor" fill="none" />
    <Path
      id="Path_7746"
      d="m18.292 18.438-1.777-2.665A1.734 1.734 0 0 0 15.072 15a1.734 1.734 0 0 1-1.551-.959l-.133-.266a1.735 1.735 0 0 1 0-1.551l1.133-2.266A1.733 1.733 0 0 1 16.072 9h4.408"
      stroke="currentColor"
      fill="none"
    />
    <Path id="Path_7747" d="M12 3a9 9 0 0 1 0 18" stroke="currentColor" fill="none" />
    <Path id="Path_7748" d="M12 21a9 9 0 0 1 0-18" stroke="currentColor" fill="none" />
  </Svg>
);

export default SvgComponent;
