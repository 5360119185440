import country from 'country-list-js';
import { LinearGradient } from 'expo-linear-gradient';
import i18n from 'i18n-js';
import { Spinner } from 'native-base';
import React, { useCallback, useEffect, useRef } from 'react';
import { Animated, Dimensions, Image, View, ScrollView, TouchableWithoutFeedback, Platform } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import styled from 'styled-components/native';

import ArrowLeft from '../../components/svg/ArrowLeft';
import IconChat from '../../components/svg/IconChat';
import IconClose from '../../components/svg/IconClose';
import IconReport from '../../components/svg/IconReport';
import calculateAgeFromDate from '../../utils/calculateAgeFromDate';
import IconEducation from '../svg/IconEducation';
import IconHeight from '../svg/IconHeight';
import IconJob from '../svg/IconJob';
import IconLocation_ from '../svg/IconLocation';
import IconLoveEmpty from '../svg/IconLoveEmpty';
import IconLoveFill from '../svg/IconLoveFill';
import IconMartialStatus from '../svg/IconMartialStatus';
import { useTheme } from '../template/ThemeProvider';
import HeaderWithIcons from './HeaderWithIcons';
const { width, height } = Dimensions.get('screen');

function RenderLikeDislikeBtn({ children, isLike = false, isFilled = false, isLoading = false, isChat = false, onClick }) {
  const scale = useRef(new Animated.Value(1)).current;

  const animateScale = useCallback(
    (newValue) => {
      Animated.spring(scale, {
        toValue: newValue,
        friction: 4,
        useNativeDriver: true,
      }).start();
    },
    [scale]
  );

  return (
    <TouchableWithoutFeedback
      onPressIn={() => animateScale(0.8)}
      delayPressIn={0}
      onPressOut={() => {
        animateScale(1);
        onClick();
      }}
      delayPressOut={110}>
      {isLike ? (
        <LikeBtn isFilled={isFilled} isLoading={isLoading} style={{ transform: [{ scale }] }}>
          {children}
        </LikeBtn>
      ) : isChat ? (
        <ChatBtn isFilled={isFilled} style={{ transform: [{ scale }] }}>
          {children}
        </ChatBtn>
      ) : (
        <DislikeBtn isFilled={isFilled} style={{ transform: [{ scale }] }}>
          {children}
        </DislikeBtn>
      )}
    </TouchableWithoutFeedback>
  );
}

export default function UserProfileCard(props) {
  const { userData, handleChoice, length, navigation, showDislike, isLiked, isLoadingChoice, disableActions } = props;
  const { name, birthdate, imagesUrls, _id, profile, avatar, countryCode, city, genderEnumId } = userData || {};
  const { colors, isDark } = useTheme();
  const scrollRef = useRef();

  const userCountry = country.findByIso2(countryCode?.toUpperCase())?.name;
  const userCity = city;

  useEffect(() => {
    scrollRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
  }, [length]);

  function handleMsgUser() {
    navigation.navigate('ChatScreen', { userData, userId: _id, userName: name, userImagesUrls: imagesUrls, avatar });
  }

  return (
    <ScrollView ref={scrollRef} showsVerticalScrollIndicator={false}>
      <Container>
        {/* <BgImage source={require('../../assets/images/userprofilebg1.jpg')} />
        <HeaderWithIcons
          leftNode={
            <IconBox>
              <ArrowLeft fill="#ffffff" />
            </IconBox>
          }
          rightNode={
            <IconBox style={{ paddingTop: 15 }}>
              <IconReport color="#ffffff" />
            </IconBox>
          }
          onPress={() => navigation.goBack()}
        /> */}
        <TopCardContainer>
          <TopCard>
            <UserImage
              source={avatar ? { uri: avatar } : require('../../assets/images/defaultavatar1.png')}
              style={{ height: Platform.OS === 'web' ? height / 2 : width }}
            />
            <GradientContainer colors={['transparent', '#00000081']}>
              <LeftBox>
                <Name>{name}</Name>
                <SubTitle>{birthdate && i18n.t('userprofile.age') + ' ' + calculateAgeFromDate(birthdate)}</SubTitle>
              </LeftBox>
              {userCity ? (
                <CardLocationBox>
                  <LocationIcon color="#fff" />
                  <LocationText>
                    {userCity}, {userCountry}
                  </LocationText>
                </CardLocationBox>
              ) : (
                <View style={{ marginLeft: 'auto' }} />
              )}
            </GradientContainer>
            {!disableActions && (
              <LikesContainer>
                {showDislike && (
                  <RenderLikeDislikeBtn onClick={() => handleChoice(-1)}>
                    <IconClose color={isDark ? colors.white : colors.dusk} />
                  </RenderLikeDislikeBtn>
                )}
                <RenderLikeDislikeBtn isChat onClick={() => handleMsgUser()}>
                  <IconChat color={isDark ? colors.white : colors.dusk} />
                </RenderLikeDislikeBtn>

                <RenderLikeDislikeBtn isLike isFilled={isLiked} isLoading={isLoadingChoice} onClick={() => !isLoadingChoice && handleChoice(1)}>
                  {isLoadingChoice ? <Spinner size="sm" color={isDark ? '#fff' : '#363B45'} /> : isLiked ? <IconLoveFill /> : <IconLoveEmpty />}
                </RenderLikeDislikeBtn>
              </LikesContainer>
            )}
          </TopCard>
        </TopCardContainer>

        <SummaryInfo>
          <SummaryItem>
            <SummaryIcon>
              <IconMartialStatus />
            </SummaryIcon>
            <SummaryTxtBox>
              <SummaryTitle>{i18n.t('userprofile.marital_status')}</SummaryTitle>
              <SummarySubTitle>
                {profile?.martialStatus ? i18n.t(`edit_my_profile.martialstatusoptions.${profile?.martialStatus}`) : '-'}
              </SummarySubTitle>
            </SummaryTxtBox>
          </SummaryItem>
          <SummaryItem>
            <SummaryIcon>
              <IconHeight />
            </SummaryIcon>
            <SummaryTxtBox>
              <SummaryTitle>{i18n.t('userprofile.height')}</SummaryTitle>
              <SummarySubTitle>{profile?.height || '-'}</SummarySubTitle>
            </SummaryTxtBox>
          </SummaryItem>
          <SummaryItem>
            <SummaryIcon>
              <IconJob />
            </SummaryIcon>
            <SummaryTxtBox>
              <SummaryTitle>{i18n.t('userprofile.job_status')}</SummaryTitle>
              <SummarySubTitle>
                {profile?.jobStatusEnumId ? i18n.t(`edit_my_profile.jobStatusEnumIdOptions.${profile?.jobStatusEnumId}`) : '-'}
              </SummarySubTitle>
            </SummaryTxtBox>
          </SummaryItem>
          <SummaryItem>
            <SummaryIcon>
              <IconEducation />
            </SummaryIcon>
            <SummaryTxtBox>
              <SummaryTitle>{i18n.t('userprofile.education')}</SummaryTitle>
              <SummarySubTitle>
                {profile?.educationEnumId ? i18n.t(`edit_my_profile.educationEnumIdOptions.${profile?.educationEnumId}`) : '-'}
              </SummarySubTitle>
            </SummaryTxtBox>
          </SummaryItem>
        </SummaryInfo>

        {/* <TouchableOpacity onPress={() => navigation.navigate('UserProfileGalleryScreen')}> */}
        <TouchableOpacity>
          <MiniGallery>
            <GalleryImageBox>
              <GalleryImage
                source={
                  imagesUrls?.[0]
                    ? { uri: imagesUrls?.[0] }
                    : genderEnumId === 1
                    ? require('../../assets/images/defaultavatar1.png')
                    : require('../../assets/images/defaultavatar2.png')
                }
              />
            </GalleryImageBox>
            <GalleryImageBox>
              <GalleryImage
                source={
                  imagesUrls?.[1]
                    ? { uri: imagesUrls?.[1] }
                    : genderEnumId === 1
                    ? require('../../assets/images/defaultavatar1.png')
                    : require('../../assets/images/defaultavatar2.png')
                }
              />
            </GalleryImageBox>
            <GalleryImageBox>
              <GalleryImage
                source={
                  imagesUrls?.[2]
                    ? { uri: imagesUrls?.[2] }
                    : genderEnumId === 1
                    ? require('../../assets/images/defaultavatar1.png')
                    : require('../../assets/images/defaultavatar2.png')
                }
              />
            </GalleryImageBox>
          </MiniGallery>
        </TouchableOpacity>

        <MoreInfo>
          <MoreInfoItem>
            <MoreInfoItemTitle>{i18n.t('userprofile.ethnicity')}</MoreInfoItemTitle>
            <MoreInfoItemSubTitle>
              {profile?.ethnicityEnumId ? i18n.t(`edit_my_profile.ethnicityEnumIdOptions.${profile?.ethnicityEnumId}`) : '-'}
            </MoreInfoItemSubTitle>
          </MoreInfoItem>
          <MoreInfoItem>
            <MoreInfoItemTitle>{i18n.t('userprofile.language')}</MoreInfoItemTitle>
            <MoreInfoItemSubTitle>
              {profile?.languageEnumId ? i18n.t(`edit_my_profile.languageEnumIdOptions.${profile?.languageEnumId}`) : '-'}
            </MoreInfoItemSubTitle>
          </MoreInfoItem>
          <MoreInfoSeperator />
          <MoreInfoItem>
            <MoreInfoItemTitle>{i18n.t('userprofile.interest')}</MoreInfoItemTitle>
            <MoreInfoItemSubTitle>
              {profile?.interestEnumId ? i18n.t(`edit_my_profile.interestEnumIdOptions.${profile?.interestEnumId}`) : '-'}
            </MoreInfoItemSubTitle>
          </MoreInfoItem>
          <MoreInfoItem>
            <MoreInfoItemTitle>{i18n.t('userprofile.children')}</MoreInfoItemTitle>
            <MoreInfoItemSubTitle>
              {profile?.childrensEnumId ? i18n.t(`edit_my_profile.childrensEnumIdOptions.${profile?.childrensEnumId}`) : '-'}
            </MoreInfoItemSubTitle>
          </MoreInfoItem>
          <MoreInfoSeperator />
          <MoreInfoItem>
            <MoreInfoItemTitle>{i18n.t('userprofile.smoke')}</MoreInfoItemTitle>
            <MoreInfoItemSubTitle>
              {profile?.smokeEnumId ? i18n.t(`edit_my_profile.smokeEnumIdOptions.${profile?.smokeEnumId}`) : '-'}
            </MoreInfoItemSubTitle>
          </MoreInfoItem>
          <MoreInfoItem>
            <MoreInfoItemTitle>{i18n.t('userprofile.drink')}</MoreInfoItemTitle>
            <MoreInfoItemSubTitle>
              {profile?.drinkEnumId ? i18n.t(`edit_my_profile.drinkEnumIdOptions.${profile?.drinkEnumId}`) : '-'}
            </MoreInfoItemSubTitle>
          </MoreInfoItem>
        </MoreInfo>
      </Container>
    </ScrollView>
  );
}

const Container = styled.View`
  width: 100%;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  /* background-color: red; */
  height: 100%;
  border-radius: 12px;
`;
const BgImage = styled.Image`
  position: absolute;
  width: 100%;
  border-bottom-left-radius: 76px;
  height: 222px;
`;
const IconBox = styled.View`
  width: 52px;
  height: 52px;
  border: 1px solid;
  border-radius: 200px;
  border-color: ${(p) => `${p.theme.myColors.pale_grey}42`};
  padding: 17px 0 0 16px;
  ${(p) => !p.children && `border: none`}
`;
const TopCardContainer = styled.View`
  width: 100%;
  /* padding: 0 50px; */
  margin: 0 auto 40px;
`;
const TopCard = styled.View`
  position: relative;
`;
const UserImage = styled.Image`
  width: 100%;
  border-radius: 12px;
`;
const GradientContainer = styled(LinearGradient)`
  position: absolute;
  bottom: 0;
  top: 38%;
  right: 0;
  left: 0;
  padding: 0 22px 46px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;
const LeftBox = styled.View``;
const CardLocationBox = styled.View`
  background-color: ${(p) => p.theme.myColors.bright_sky_blue};
  border-radius: 4px;
  padding: 1px 8px 2.1px 3px;
  width: auto;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const LocationIcon = styled(IconLocation_)`
  /* margin: 3px 1px 0 0; */
`;
const LocationText = styled.Text`
  font-family: Gilroy-Medium;
  color: ${(p) => p.theme.myColors.white};
  font-size: 12px;
  font-weight: 600;
`;

const Name = styled.Text`
  color: #fff;
  font-family: Gilroy-Bold;
  font-size: 21px;
`;
const SubTitle = styled.Text`
  color: #fff;
  font-family: Gilroy-Light;
  font-size: 19px;
`;
const LikesContainer = styled.View`
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;
const LikeBtn = styled(Animated.View)`
  width: 52px;
  height: 52px;
  background-color: ${(p) =>
    p?.isLoading ? (p.theme?.isDark ? '#363B45' : '#EFEFF5') : p?.isFilled ? p.theme?.myColors?.watermelon : p.theme?.isDark ? '#602E3B' : '#FEE0E5'};
  padding: ${(p) => (p?.isLoading ? '15px 5px' : p?.isFilled ? '12px 0px 0 10px' : '15px 0px 0 13px')};
  border-radius: 1000px;
  border: 3px solid;
  border-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark : p.theme.myColors.white)};
`;
const DislikeBtn = styled(Animated.View)`
  width: 52px;
  height: 52px;
  background-color: ${(p) => (p.theme?.isDark ? '#363B45' : '#EFEFF5')};
  padding: 18px 0px 0 18px;
  border-radius: 1000px;
  border: 3px solid;
  border-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark : p.theme.myColors.white)};
`;
const ChatBtn = styled(Animated.View)`
  width: 52px;
  height: 52px;
  background-color: ${(p) => (p.theme?.isDark ? '#363B45' : '#EFEFF5')};
  padding: 14px 0px 0 14px;
  border-radius: 1000px;
  border: 3px solid;
  border-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark : p.theme.myColors.white)};
`;

const SummaryInfo = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 50px; */
`;
const SummaryItem = styled.View`
  flex: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 20px;
  /* background-color: blue; */
`;
const SummaryIcon = styled.View`
  padding: 13px;
  border-radius: 12px;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark_grey_blue2 : p.theme.myColors.pale_lilac)};
`;
const SummaryTxtBox = styled.View`
  margin-left: 9px;
`;
const SummaryTitle = styled.Text`
  font-family: Gilroy-Light;
  font-size: 12px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.bluey_grey)};
`;
const SummarySubTitle = styled.Text`
  font-family: Gilroy-SemiBold;
  font-size: 12px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dark2)};
  margin-top: 4px;
  text-align: left;
`;
const MiniGallery = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark_grey_blue2 : p.theme.myColors.pale_grey2)};
  border-radius: 12px;
  padding: 7px 3.5px;
  margin-top: 22px;
`;
const GalleryImageBox = styled.View`
  width: 33.333%;
  height: ${Platform.OS === 'web' ? '120px' : 'auto'};
  aspect-ratio: 1;
  /* padding: 5px; */
  padding: 0 3.5px;
`;
const GalleryImage = styled.Image`
  width: 100%;
  height: 100%;
  border-radius: 12px;
`;
const MoreInfo = styled.View`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
`;
const MoreInfoItem = styled.View`
  flex: 50%;
  margin: 14px 0 20px;
`;
const MoreInfoItemTitle = styled.Text`
  font-family: Gilroy-Light;
  font-size: 12px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.bluey_grey)};
  text-align: left;
`;
const MoreInfoItemSubTitle = styled.Text`
  font-family: Gilroy-SemiBold;
  font-size: 12px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dark2)};
  text-align: left;
`;
const MoreInfoSeperator = styled.Text`
  height: 1px;
  width: 100%;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey2)};
`;
