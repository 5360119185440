import * as React from 'react';
import Svg, { Rect, Path, Circle } from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg data-name="character design" xmlns="http://www.w3.org/2000/svg" width={46.984} height={89.871} viewBox="0 0 46.984 89.871" {...props}>
      <Rect data-name="Rectangle 2612" width={46.984} height={45.948} rx={22.974} transform="translate(0 41.331)" fill="#fff" />
      <Path data-name="Path 20291" d="M29.569 32.661l-6.076.574.018 12.154a17.452 17.452 0 007.893-2.89s-2.669-5.28-1.835-9.838z" fill="#f7cbb6" />
      <Path
        data-name="Path 20292"
        d="M15.507 42.661a16.27 16.27 0 007.966 2.723l.02-12.154-6.078-.569c.833 4.558-1.908 10-1.908 10z"
        fill="#f7cbb6"
      />
      <Path
        data-name="Path 20293"
        d="M17.386 36.734a20.859 20.859 0 003.453.794c.458.058 1.779.12 2.648.222v-4.509l-6.072-.58a12.228 12.228 0 01-.029 4.073z"
        fill="#e5bbaa"
      />
      <Path
        data-name="Path 20294"
        d="M29.569 32.661l-6.076.574v4.509a4.173 4.173 0 01.711.12c2.739.952 4.6 4.466 5.209 5.78a18.1 18.1 0 001.983-1.147s-2.661-5.278-1.827-9.836z"
        fill="#e5bbaa"
      />
      <Path
        data-name="Path 20295"
        d="M26.485 44.819s2.268.514 1.156 6.294-5.52 14.562-4.853 16.786 8.337-2.779 8.337-2.779l.889-18.9-2.972-2.779a7.669 7.669 0 01-2.557 1.378z"
        fill="#d1d3d4"
        opacity={0.68}
      />
      <Path
        data-name="Path 20296"
        d="M33.985 19.906c.369-.258 1.579-1.1 2.334-.723 1.094.542.989 3.528-.029 5.725-.774 1.667-2.481 3.766-3.557 3.417-1.283-.416-1.507-4.264-.249-6.67a4.78 4.78 0 011.501-1.749z"
        fill="#f7cbb6"
      />
      <Path
        data-name="Path 20297"
        d="M11.977 19.906c-.371-.258-1.581-1.1-2.334-.723-1.1.542-.989 3.528.027 5.725.774 1.667 2.483 3.766 3.557 3.417 1.283-.416 1.51-4.264.251-6.67a4.8 4.8 0 00-1.501-1.749z"
        fill="#f7cbb6"
      />
      <Path
        data-name="Path 20298"
        d="M46.984 80.658V51.931a10.629 10.629 0 00-10.6-10.6h-5.307a6.308 6.308 0 01-3.177 2.223l1.667 4.558s-3.724 15.285-6 18.9l-.038.042-.036-.042c-2.279-3.613-6-18.9-6-18.9l1.667-4.558c-1.779-.336-3.159-.889-3.177-2.223H10.6A10.629 10.629 0 000 51.931v28.727c.017 5.848 4.753 6.606 10.6 6.623h25.79c5.845-.021 10.577-.778 10.594-6.623z"
        fill="#20caff"
      />
      <Path
        data-name="Path 20299"
        d="M38.88 53.781q.078 4.169.091 8.337c.016 2.779 0 5.558 0 8.337l-.027 8.337c0 2.779-.036 5.558-.058 8.337h-.056c-.024-2.779-.06-5.558-.06-8.337l-.027-8.337c-.011-2.779-.022-5.558 0-8.337s.04-5.558.093-8.337z"
        fill="#231f20"
      />
      <Path data-name="Path 20300" d="M8.042 69.862s18.582 6.948 30.828.594l.076 14.822-17.862 4.593L8.56 86.612l-.371-2.446z" fill="#fff" />
      <Path
        data-name="Path 20301"
        d="M8.337 86.908a17.639 17.639 0 003.224-10.116c-.111-5.78-3-14.34-2.334-17.9s-.816-4.669-.816-4.669z"
        fill="#56a0c1"
        opacity={0.3}
      />
      <Path
        data-name="Path 20302"
        d="M8.337 53.781c.089 2.779.131 5.558.153 8.337l.022 4.169-.022 4.169q0 4.169-.047 8.337c-.033 2.779-.06 5.558-.116 8.337h-.056q-.082-4.169-.118-8.337c-.04-2.779-.04-5.558-.04-8.337l-.011-4.169.022-4.169c.02-2.779.064-5.558.153-8.337z"
        fill="#231f20"
      />
      <Path
        data-name="Path 20303"
        d="M38.795 54.226a17.64 17.64 0 00-3.224 10.116c.111 5.78 3 14.34 2.334 17.9s1.056 4.575 1.056 4.575z"
        fill="#56a0c1"
        opacity={0.3}
      />
      <Path
        data-name="Path 20304"
        d="M8.337 69.984q3.817.729 7.659 1.276c2.559.356 5.127.656 7.7.829 1.287.082 2.575.158 3.862.162s2.577 0 3.857-.093c.322-.013.64-.056.96-.082l.48-.047.478-.062c.638-.073 1.27-.189 1.9-.316a14.505 14.505 0 003.653-1.221l.027.049a12.777 12.777 0 01-3.588 1.532 25.186 25.186 0 01-3.855.667 45.593 45.593 0 01-7.81.044c-1.3-.1-2.595-.222-3.882-.391s-2.577-.353-3.855-.585a75.568 75.568 0 01-7.586-1.71z"
        fill="#231f20"
      />
      <Path
        data-name="Path 20305"
        d="M10.018 74.433c1.136.536 2.281 1.052 3.439 1.532s2.321.949 3.493 1.392a74.454 74.454 0 007.143 2.305c1.207.318 2.423.6 3.651.829.614.124 1.229.222 1.845.316s1.236.165 1.859.222a26.876 26.876 0 003.735.109 20.618 20.618 0 003.717-.476l.013.053a20.348 20.348 0 01-7.5.791 31.951 31.951 0 01-3.766-.445 42.395 42.395 0 01-3.7-.823 61.077 61.077 0 01-13.951-5.743z"
        fill="#231f20"
      />
      <Path
        data-name="Path 20306"
        d="M34.287 75.594a6.179 6.179 0 01-1.043.507 8.723 8.723 0 01-1.112.34 7.715 7.715 0 01-1.147.182 5.2 5.2 0 01-1.16-.024v-.056c.38-.067.754-.116 1.125-.189s.74-.145 1.112-.238c.736-.169 1.463-.376 2.206-.571z"
        fill="#231f20"
      />
      <Path
        data-name="Path 20307"
        d="M8.353 79.882c.667.425 1.354.811 2.048 1.183s1.405.716 2.125 1.032 1.456.6 2.2.847c.376.118.749.24 1.132.333s.767.18 1.152.269v.053a14.542 14.542 0 01-2.321-.5 20.157 20.157 0 01-2.223-.84 24.688 24.688 0 01-4.135-2.328z"
        fill="#231f20"
      />
      <Circle data-name="Ellipse 337" cx={0.945} cy={0.945} r={0.945} transform="translate(13.895 50.669)" fill="#231f20" />
      <Circle data-name="Ellipse 338" cx={0.945} cy={0.945} r={0.945} transform="translate(15.062 56.394)" fill="#231f20" />
      <Circle data-name="Ellipse 339" cx={0.945} cy={0.945} r={0.945} transform="translate(16.212 61.878)" fill="#231f20" />
      <Rect data-name="Rectangle 2613" width={20.454} height={26.234} rx={9} transform="translate(13.228 8.872)" fill="#f7cbb6" />
      <Path
        data-name="Path 20308"
        d="M33.737 19.459v-3.557a7.5 7.5 0 00-7.474-7.475h-2.07l-8.575 2.668s5.929 4.373 9.782 3.631 5.707-2.519 6 .445a19.625 19.625 0 001.928 6.078z"
        fill="#e5bbaa"
        opacity={0.66}
      />
      <Path
        data-name="Path 20309"
        d="M33.789 21.321c.027-.138.062-.289.107-.445.222-.82.7-1.765 1.663-1.756a2.989 2.989 0 011.089.445c1.152 1.276-.044 5.045-1.545 7.114-.149.209-.2.458.165.24a3.9 3.9 0 002.112-2.932 5.281 5.281 0 01.376-1.334 8.446 8.446 0 00.7-2.332c.045-.385-.1-1.939-.029-2.39a8.28 8.28 0 01.349-1.367c.14-.413.587-1.463.667-1.652a3.923 3.923 0 00.269-1.152 4.526 4.526 0 00-.569-2.37 6.932 6.932 0 01-.747-.889 11.892 11.892 0 01-.236-1.334c-.253-1.7-.932-4.367-2.546-5.714-1.214-1.014-3.433-.76-3.628-.787-2.89-.4-2.9-1.7-1.736-2.317.078-.04.358-.064.024-.185a4.075 4.075 0 00-2.05.038c-.3.076-1.247.5-1.387.542a2.057 2.057 0 01-1.094.08c-.238-.051-.84-.362-1.058-.431a9.562 9.562 0 00-1.4-.345 10.77 10.77 0 00-4.475.445 7.48 7.48 0 00-2.017 1.592c-1.527 1.356-3.266 2.143-3.808 3.2-.06.116-.293 1.112-.327 1.3-.084.48-.133.876-.162 1.149a1.365 1.365 0 01-.08.36 1.035 1.035 0 01-.329 0 2.981 2.981 0 00-2.223.594c-.245.222-.06.162.036.222.285.211.827.7.607 1.218a4.067 4.067 0 01-.562.6 4.315 4.315 0 00-1.454 3.084c.022.14.244.238.411.04a3.828 3.828 0 011.278-1.223c.129-.071.267 0 .158.191-.574 1-2.3 4.891-1.334 6.892.029.058.076.4.211.013.164-.474 1.167-1.167 2.125.082a4.407 4.407 0 01.638 1.685c.222.769.756 2.157 1.1 2.036.189-.067.347-.471.485-.956a7.091 7.091 0 00.062-2.6 15.479 15.479 0 01-.169-3.368c.265-1.73.909-3.722 1.556-4.293.209-.182.4-.356 1.383-.3 1.187.071 2.31.156 3.113.156 1.056 0 1.612-.222 2.279.333a2.44 2.44 0 002.986.382c.349-.242.567-.411.72-.522a8.44 8.44 0 012.352-.3 11.9 11.9 0 013.19.036c.756.176 1.032 1.063 1.216 1.345 1.1 1.676.68 5.062.587 5.536-.24 1.209-.542 3.542.3 4.876.093.149.253.313.3-.093a5.053 5.053 0 01.1-.565"
        fill="#23282d"
      />
      <Path data-name="Path 20310" d="M34.016 24.601s.627-5.151 2.019-3.9-2.019 3.9-2.019 3.9z" fill="#e5bbaa" />
      <Path data-name="Path 20311" d="M12.078 24.601s-.627-5.151-2.019-3.9 2.019 3.9 2.019 3.9z" fill="#e5bbaa" />
    </Svg>
  );
}

export default SvgComponent;
