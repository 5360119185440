import * as Linking from 'expo-linking';
import i18n from 'i18n-js';
import { Actionsheet, Text, useDisclose, useToast } from 'native-base';
import React, { useEffect, useState } from 'react';
import { TouchableOpacity, FlatList, ScrollView, RefreshControl, Alert, Share, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';

import IconArrowRight from '../../components/svg/IconArrowRight';
import IconEdit1 from '../../components/svg/IconEdit1';
import IconFaq from '../../components/svg/IconFaq';
import IconFeedback from '../../components/svg/IconFeedback';
import IconMoon from '../../components/svg/IconMoon';
import IconPrivacy from '../../components/svg/IconPrivacy';
import IconSafety from '../../components/svg/IconSafety';
import IconSettings from '../../components/svg/IconSettings';
import IconShare from '../../components/svg/IconShare';
import IconSubscribeStar from '../../components/svg/IconSubscribeStar';
import IconSun from '../../components/svg/IconSun';
import IconTerms from '../../components/svg/IconTerms';
import { useTheme } from '../../components/template/ThemeProvider';
import { fetchUserProfile, setUserSettings } from '../../redux/slices/UserSlice';
import calculateAgeFromDate from '../../utils/calculateAgeFromDate';

export default function MyProfileHomeScreen({ navigation }) {
  const { colors, isDark, setScheme } = useTheme();
  const userReduxState = useSelector((state) => state.user);
  const { isOpen, onOpen, onClose } = useDisclose();
  const dispatch = useDispatch();
  const toast = useToast();

  function alert(title = undefined, msg, footer = [{ text: 'ok', onPress: () => null, style: 'cancel' }]) {
    if (Platform.OS === 'web') {
      toast.show({ title: title, status: 'info', description: msg });
    } else {
      Alert.alert(title, msg, footer);
    }
  }

  // console.log(userReduxState);
  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    // setdoRefresh((prev) => prev + 1);
    dispatch(fetchUserProfile());
    setTimeout(() => {
      setRefreshing(false);
    }, 500);
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      dispatch(fetchUserProfile());
    });
    return unsubscribe;
  }, [navigation]);

  function handleClickDangerBanner(title, message) {
    alert(title, message);
  }

  function changeAppDarkMode(mode) {
    dispatch(setUserSettings({ darkmode: `${mode}` }));
    // setTimeout(() => {
    //   onClose();
    // }, 400);
  }

  const onShare = async () => {
    try {
      const result = await Share.share({
        message: 'http://www.jewankurd.com',
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const Head = (
    <>
      <InfoContainer>
        <AvatarBox>
          <Avatar
            source={userReduxState?.userInfo?.avatar ? { uri: userReduxState?.userInfo?.avatar } : require('../../assets/images/defaultavatar1.png')}
          />
        </AvatarBox>
        <IconsContainer>
          <TouchableOpacity onPress={() => (isDark ? changeAppDarkMode('light') : changeAppDarkMode('dark'))} style={{ marginRight: 22 }}>
            {isDark ? <IconSun color={colors.white} width={25} height={25} /> : <IconMoon color={colors.dark2} width={25} height={25} />}
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navigation.navigate('Settings')}>
            <IconSettings color={isDark ? colors.white : colors.dark2} width={25} height={25} />
          </TouchableOpacity>
        </IconsContainer>
      </InfoContainer>
      {userReduxState?.userInfo?.suspend && (
        <TouchableOpacity
          onPress={() => handleClickDangerBanner('Suspend!', userReduxState?.userInfo?.suspendReason || 'Your account has been suspended!')}>
          <DangerBanner>
            <DangerBannerText>Your account is suspended! (details)</DangerBannerText>
          </DangerBanner>
        </TouchableOpacity>
      )}
      <NameProfileContainer>
        <NameContainer>
          <Name>{userReduxState?.userInfo?.name}</Name>
          <NameSubTitle>
            {userReduxState?.userInfo?.city}, {userReduxState?.userInfo?.countryCode}
          </NameSubTitle>
          <AgeTxt>
            {userReduxState?.userInfo?.birthdate && i18n.t('myprofile.age') + ' ' + calculateAgeFromDate(userReduxState?.userInfo?.birthdate)}
          </AgeTxt>
        </NameContainer>
        <TouchableOpacity onPress={() => navigation.navigate('EditMyProfile')}>
          <EditProfileBtn>
            <IconEdit1 color={isDark ? colors.light_blue_grey : colors.dusk} />
            <EditProfileTxt>{i18n.t('myprofile.edit_profile')}</EditProfileTxt>
          </EditProfileBtn>
        </TouchableOpacity>
      </NameProfileContainer>
    </>
  );

  const subScribeBanner = (
    <TouchableOpacity onPress={() => navigation.navigate('SubscriptionHomeScreen')}>
      <SubscribeNowWraper>
        <SubscribeNowContainer>
          <IconSubscribeStar />
          <SubNowTxt>{i18n.t('myprofile.subscribe_now')}</SubNowTxt>
          <MoreArrowBtn>
            <IconArrowRight color={colors.cornflower} />
          </MoreArrowBtn>
        </SubscribeNowContainer>
      </SubscribeNowWraper>
    </TouchableOpacity>
  );

  function List1(params) {
    const DATA = [
      {
        title: i18n.t('myprofile.menu_invite_friends'),
        icon: <IconShare />,
        onClick: () => onShare(),
      },
      {
        title: i18n.t('myprofile.menu_feedback'),
        icon: <IconFeedback />,
        onClick: () => Linking.openURL('mailto:support@jewankurd.com'),
      },
      {
        title: i18n.t('myprofile.menu_terms_and_conditions'),
        icon: <IconTerms />,
        onClick: () => Linking.openURL('http://www.jewankurd.com/en-us/terms'),
      },
      {
        title: i18n.t('myprofile.menu_privacy_policy'),
        icon: <IconPrivacy />,
        onClick: () => Linking.openURL('https://www.jewankurd.com/en-us/privacy'),
      },
      {
        title: i18n.t('myprofile.menu_faq'),
        icon: <IconFaq />,
        onClick: () => Linking.openURL('https://www.jewankurd.com/en-us/faq'),
      },
      {
        title: i18n.t('myprofile.menu_dating_safety_tips'),
        icon: <IconSafety />,
        onClick: () => Linking.openURL('https://www.jewankurd.com/tips'),
      },
    ];

    const Item = ({ title, icon, onClick }) => (
      <TouchableOpacity onPress={onClick || null}>
        <MenuItem>
          {icon}
          <MenuTxt>{title}</MenuTxt>
          <IconArrowRight color={isDark ? colors.white : colors.dark2} />
        </MenuItem>
      </TouchableOpacity>
    );

    return (
      <FlatList
        data={DATA}
        // scrollEnabled={false}
        renderItem={({ item }) => <Item title={item.title} icon={item?.icon} onClick={item?.onClick} />}
        keyExtractor={(item, index) => index}
        ItemSeparatorComponent={({ highlighted }) => <MenuItemSeperator />}
      />
    );
  }

  const FollowUs = (
    <FollowUsContainer>
      <FollowUsTxt>{i18n.t('myprofile.follow_us_in_social')}</FollowUsTxt>
      <SocialIconsContainer>
        <TouchableOpacity onPress={() => Linking.openURL('https://instagram.com/jewankurd')}>
          <SocialIconImage source={require('../../assets/images/instagram1.png')} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => Linking.openURL('https://www.facebook.com/profile.php?id=100072032481435')}>
          <SocialIconImage source={require('../../assets/images/facebook1.png')} />
        </TouchableOpacity>
      </SocialIconsContainer>
    </FollowUsContainer>
  );

  return (
    <Container>
      <ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
        {Head}
        {subScribeBanner}
        <List1 />
        {FollowUs}
      </ScrollView>

      {/* <Actionsheet isOpen={isOpen} onClose={onClose}>
        <Actionsheet.Content>
          <Actionsheet.Item onPress={() => changeAppDarkMode('light')}>Light</Actionsheet.Item>
          <Actionsheet.Item onPress={() => changeAppDarkMode('dark')}>Dark</Actionsheet.Item>
          <Actionsheet.Item onPress={() => changeAppDarkMode('inherit')}>Device default</Actionsheet.Item>
        </Actionsheet.Content>
      </Actionsheet> */}
    </Container>
  );
}

const Container = styled.View`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
  /* padding: 42px 39px 0; */
`;
const InfoContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 42px 39px 0;
`;
const AvatarBox = styled.View`
  border-radius: 1000px;
  padding: 4px;
  border: 1.5px solid;
  border-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
`;
const Avatar = styled.Image`
  border-radius: 1000px;
  width: 93px;
  height: ${Platform.OS === 'web' ? '93px' : 'auto'};
  aspect-ratio: 1;
`;
const IconsContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-top: 20px; */
`;
const NameProfileContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 13px;
  padding: 0 39px 0;
`;
const DangerBanner = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 13px;
  padding: 15px 39px;
  background: red;
`;
const DangerBannerText = styled.Text`
  font-size: 14px;
  color: #fff;
  font-family: Gilroy-Bold;
`;
const NameContainer = styled.View``;
const Name = styled.Text`
  font-size: 18px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dark2)};
  font-family: Gilroy-Bold;
`;

const NameSubTitle = styled.Text`
  font-family: Gilroy-Medium;
  font-size: 14px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.slate_blue)};
  margin-top: 4px;
  text-align: left;
`;
const AgeTxt = styled.Text`
  font-family: Gilroy-Medium;
  font-size: 12px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.slate_blue)};
  margin-top: 4px;
  text-align: left;
`;
const EditProfileBtn = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid;
  border-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
  border-radius: 6px;
  padding: 10px 16px;
  margin: 0 0 auto;
`;
const EditProfileTxt = styled.Text`
  margin-left: 5px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.dusk)};
`;
const SubscribeNowWraper = styled.View`
  padding: 0 39px 0;
`;
const SubscribeNowContainer = styled.View`
  margin-top: 31px;
  margin-bottom: 61px;
  background-color: ${(p) => p.theme.myColors.cornflower};
  border-radius: 18px;
  padding: 7px 31px 7px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const SubNowTxt = styled.Text`
  color: ${(p) => p.theme.myColors.white};
  font-family: Gilroy-SemiBold;
  font-size: 16px;
  flex: 1;
  margin-left: 0px;
  text-align: center;
`;
const MoreArrowBtn = styled.View`
  background: ${(p) => p.theme.myColors.white};
  padding: 6.5px 0 0 9.5px;
  border-radius: 100px;
  width: 24px;
  height: 24px;
`;
const MenuItem = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 39px;
`;
const MenuTxt = styled.Text`
  flex: 1;
  font-family: Gilroy-Medium;
  font-size: 14px;
  margin-left: 11px;
  text-align: left;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.dusk)};
`;
const MenuItemSeperator = styled.View`
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey2)};
  height: 1px;
  margin-left: 20px;
`;
const FollowUsContainer = styled.View`
  margin-top: 53px;
  padding-bottom: 30px;
`;
const FollowUsTxt = styled.Text`
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.dusk)};
  font-family: Gilroy-Medium;
  font-size: 14px;
  text-align: center;
`;
const SocialIconsContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 17px;
`;
const SocialIconImage = styled.Image`
  margin: 0 5px;
  height: 50px;
  width: 50px;
  border-radius: 9999px;
`;
