import * as React from 'react';
import Svg, { Defs, G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

const SvgComponent = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" color="#000" width={24} height={24} {...props}>
    <Defs></Defs>
    <G id="Group_653">
      <Path id="Path_7874" d="M16.95 7.05a7 7 0 1 1-9.9 0 7 7 0 0 1 9.9 0" stroke="currentColor" fill="none" />
      <Path id="Path_7875" d="M13.414 10.586a2 2 0 1 1-2.828 0 2 2 0 0 1 2.828 0" stroke="currentColor" fill="none" />
      <Path id="Path_7876" d="M12 21v-4" stroke="currentColor" fill="none" />
      <Path id="Path_7877" d="M12 3v4" stroke="currentColor" fill="none" />
      <Path id="Path_7878" d="M7 12H3" stroke="currentColor" fill="none" />
      <Path id="Path_7879" d="M21 12h-4" stroke="currentColor" fill="none" />
    </G>
    <Path id="Path_7880" fill="none" d="M0 0h24v24H0z" />
  </Svg>
);

export default SvgComponent;
